<template>
    <div class="import-data">
        <form ref="fileForm"
              class="import-container"
              :class="{dragover : dragoverStatus}"
              @submit.prevent="submit"
              @dragover.prevent @drop.prevent
              @dragover="dragover"
              @dragleave="dragleave"
              @drop="dragFile"
        >
            <div class="title">{{ $t('Import data') }}</div>
            <div class="description">
                {{ $t('You can import your cinema sales data using the form below:') }}
            </div>
            <div class="file-container">
                <div class="errors" v-if="this.errors">{{ errors.excel }}</div>
                <input ref="file" hidden @change="uploadFile" name="file" id="addFile" type="file">
                <div class="label">{{ $t('Sales file') }}</div>
                <label class="label-file" for="addFile">
                    <span v-if="!this.fileSize">{{ $t('Select a file') }}</span>
                    <span class="file-name" v-else-if="this.file">{{ this.file.name }}</span>
                    <span class="material-icons">get_app</span>
                </label>
                <button type="submit" class="btn">{{ $t('Import') }}</button>
            </div>
            <div class="progress-container" v-if="this.fileSize">
                <div class="progress">
                    <div
                        class="progress-bar progress-bar-striped progress-bar-animated"
                        :style="{width: statusBar + '%'}"
                        role="progressbar"
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    >
                        <span v-if="statusBar">{{ statusBar }}%</span>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import "@/plugins/echo"


export default {
    name: "ImportData",
    data() {
        return {
            file: null,
            errors: {
                excel: ''
            },
            progressLoaded: null,
            fileSize: null,
            statusBar: null,
            dragoverStatus: false,
        }
    },
    methods: {
        async submit() {
            if (!this.file){
                this.errors.excel = this.$t('Please upload a valid file')
            }

            let config = {
                onUploadProgress: progressEvent => {
                    this.progressLoaded = progressEvent.loaded
                    this.statusBar = Math.round((this.progressLoaded * 100) / this.fileSize)
                    if (this.statusBar >= 100) {
                        this.statusBar = 100
                    }
                }
            }
            let formData = new FormData()
            formData.append('excel', this.file)
            if (this.file) {
                let resp = await this.api.statistics.sentImportStatistics(formData, config).then(this.goodResponse).catch(this.handleError)
                this.file = null
                this.$refs.file.value = ''
            }
        },
        goodResponse() {
            this.fileUploaded()
        },
        uploadFile() {
            this.resetLoader()

            this.file = this.$refs.file.files[0]
            if (this.file){
                this.fileCheck()
            }
        },
        handleError(resp) {
            this.resetLoader()
            if (resp.statusCode === 422) {
                this.errors = resp.messages;
            }
        },
        fileUploaded() {
            this.toast.info(this.$t('File in processing'))
            this.file = null
            this.resetLoader()
        },
        resetLoader() {
            this.file = null
            this.errors.excel = ''
            this.progressLoaded = null
            this.fileSize = null
            this.statusBar = null
        },
        fileCheck() {
            this.dragoverStatus = false
            this.errors.excel = ''

            let extension = this.file.name.match(/\.[0-9a-z]+$/i)[0]
            if (extension !== '.xlsx' && extension !== '.csv') {
                this.errors.excel = this.$t('Only xlsx and csv files are supported')
                this.file = null
                this.$refs.file.value = ''

                return false
            }
            if (+this.file.size > 8000000) {
                this.errors.excel = this.$t('File too large')
                this.file = null
                this.$refs.file.value = ''

                return false
            }
            this.fileSize = this.file.size
            this.$refs.file.value = ''

        },
        dragover() {
            this.dragoverStatus = true
        },
        dragleave() {
            this.dragoverStatus = false
        },
        dragFile(e) {
            this.resetLoader()

            this.file = e.dataTransfer.files[0]
            if (this.file){
                this.fileCheck()
            }
        }
    },
}
</script>

<style scoped lang="scss">
.import-data {
    width: 100%;
    background-color: white;
    padding-bottom: 95px;
    @media (max-width: 768px) {
        margin-top: 20px;
        padding-top: 20px;
        padding-bottom: 35px;
    }

    .dragover {
        background-color: #cad9f3;
    }

    .import-container {
        border: solid 1px #cad9f3;
        margin: 0 15px;
        min-height: 278px;
        @media (max-width: 768px) {
            margin-top: 20px;
        }
    }

    .title {
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        color: #141213;
        margin-top: 37px;
    }

    .description {
        font-size: 18px;
        line-height: 1.5;
        color: #646e78;
        display: flex;
        justify-content: center;
    }

    .file-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
        @media (max-width: 768px) {
            flex-direction: column;
            margin-top: 10px;
        }

        .errors {
            font-size: 13px;
            color: #FF496D;
            position: absolute;
            right: 0;
            bottom: auto;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .label-file {
            width: 400px;
            height: 45px;
            opacity: 0.8;
            border-radius: 2px;
            border: solid 1px #cad9f3;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding-left: 15px;
            font-size: 18px;
            color: #141213;
            font-weight: normal;
            position: relative;
            @media (max-width: 768px) {
                width: 100%;
            }

            .file-name {
                font-size: 14px;
            }

            .material-icons {
                color: $color_darkblue;
                position: absolute;
                right: 16px;
                font-size: 20px;
            }
        }

        .label {
            font-size: 16px;
            color: #646e78;
            margin-right: 17px;
            @media (max-width: 768px) {
                margin-bottom: 20px;
            }
        }

        .btn {
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 195px;
            height: 45px;
            border-radius: 2px;
            background-color: $color_darkblue;
            color: white;
            margin-left: 30px;
            font-size: 12px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 2px;
            text-align: center;
            @media (max-width: 768px) {
                margin-top: 20px;
                margin-left: 0;
            }

            &:hover {
                box-shadow: 0 4px 10px 0 rgba(53, 0, 212, 0.25);
                color: $color_lightpink;
            }
        }
    }

    .progress-container {
        width: 400px;
        margin: 0 auto;
        padding: 0;
        position: relative;
        right: 50px;

        .progress, .progress-bar {
            height: 20px;
            padding: 0;
        }

        .progress {
            width: 100%;
        }

        .progress-bar {
            background-color: $color_darkblue;
        }
    }
}


</style>
