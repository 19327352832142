<template>
    <div class="statistics-cinema container">
        <PageHeader
            class="title"
            :title="$t('Home page')"
        />
        <div class="download-template">
            <router-link target="_blank" to="/api/v1/tickets/export/example">
                <button type="button" class="btn-template">
                    {{$t('Download template')}}
                </button>
            </router-link>
        </div>
        <div class="table">
            <TableSecondary
                :items="statistics"
                :headerTitle="$t('Sales statistics')"
                :itemsMeta="meta"
                :header-columns="columns"
                scroll="md"
                :selectInfo="selectOptions"
                :preloader="preloader"
                @getSelectValue="getSelectValue"
                :clearable="true"
            />
            <ImportData />
        </div>
    </div>
</template>

<script>
import SelectComponent from "../../components/ui/forms/SelectComponent";
import PageHeader from "../../components/PageHeader";
import TableSecondary from "../../components/ui/tables/TableSecondary";
import ImportData from "../../components/statistics/ImportData";

export default {
    name: "StatisticsCinema",
    components: {
        SelectComponent,
        PageHeader,
        TableSecondary,
        ImportData
    },
    data() {
        return {
            selectValue: null,
            selectOptions: {
                defaultValue: 1,
                options: [
                    {id: 1, label: this.$t('All')},
                    {id: 2, label: this.$t('Cashier')},
                    {id: 3, label: this.$t('Online')},
                ]
            },
            meta: [],
            saleList: [],
            preloader: true,
            type: null,
            inProgress: false,
            columns: [
                {
                    id: 1,
                    label: this.$t('Period'),
                    sort: false,
                    attribute: "period",
                },
                {
                    id: 2,
                    label: this.$t('Tickets'),
                    sort: false,
                    attribute: "tickets",
                },
                {
                    id: 3,
                    label: this.$t('Sum'),
                    sort: false,
                    attribute: "sum",
                },
            ],
            statistics: [],
        }
    },
    // computed: {
    //     list() {
    //         return this.$store.getters.getNotificationsList
    //     }
    // },
    // watch: {
    //     list(){
    //         this.getSaleList()
    //     }
    // },
    methods: {
        async getSaleList(){
            this.preloader = true
            this.statistics = []
            if (!this.inProgress){
                this.inProgress = true
                let resp = await this.api.statistics.getSalesStatistics(this.type)
                this.inProgress = false
                this.saleList = resp
                for (let [key, value] of Object.entries(this.saleList)) {
                    if (key === 'yesterday') {
                        key = this.$t('Yesterday')
                    } else if (key === 'current_week') {
                        key = this.$t('This week')
                    } else if (key === 'previous_week') {
                        key = this.$t('Last week')
                    } else if (key === 'current_month') {
                        key = this.$t('This month')
                    } else if (key === 'previous_month') {
                        key = this.$t('Last month')
                    }
                    this.statistics.push({
                        period: key,
                        tickets: value.tickets_count,
                        sum: value.tickets_amount
                    })

                }
                this.preloader = false
            }

        },
        getSelectValue(value){
            this.selectOptions.defaultValue = value

            if (value === 2){
                this.type = 'cashbox'
            } else if (value === 3){
                this.type = 'online'
            } else {
                this.type = null
            }

            this.getSaleList()
        }
    },
    created() {
        this.getSaleList()
    }
}
</script>

<style scoped lang="scss">
    .statistics-cinema{
        min-height: 80vh;
        position: relative;
        .download-template{
            position: absolute;
            right: 12px;
            top: -10px;
            @media (max-width: 768px) {
                position: relative;
                right: 0;
            }
            .btn-template{
                width: 195px;
                height: 45px;
                border-radius: 2px;
                background-color: $color_blue;
                font-size: 12px;
                font-weight: 900;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                letter-spacing: 2px;
                justify-content: center;
                border: none;
                color: white;
                &:hover{
                    color: $color_lightpink;
                    box-shadow: 0 4px 10px 0 rgba(53, 0, 212, 0.25);
                }
            }
        }
        .title{
            font-size: 30px;
            font-weight: 600;
            color: #141213;
            margin-top: 30px;
        }
        .head-table{
            margin-top: 15px;
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 50px;
            padding: 5px 15px;
            border-radius: 2px;
            background-color: $color_lightpink;
            align-items: center;
            .table-title{
                font-size: 20px;
                font-weight: 600;
                color: #141213;
            }

        }
    }
    ::v-deep{
        .select-item{
            padding: 0;
            margin: 0;
            width: 238px;
            border-radius: 2px;
            background-color: #66ECFF;
            cursor: pointer;
            .v-select{
                background-color: transparent;
                height: 40px;
                border: none;
            }
            .vs__selected{
                font-size: 18px;
                color: #141213;
            }
        }
        .secondary-table{
            .table{
                .table__labelTr {
                    th:first-child{
                        width: 82%!important;
                    }
                    th:last-child{
                        padding-right: 130px;
                    }
                }
            }
        }
        @media (max-width: 768px) {
            .secondary-table.adaptive--md{
                min-width: auto;
            }
        }

        .v-select{
            height: 40px;
            .vs__search{
                cursor: pointer;
            }
        }
        .form-control{
            padding: 2.5px 7px;
        }
    }
</style>
